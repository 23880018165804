const initialValues = {
    username: '',
    email: '',
    password: '',
    role: '',
    first_name: '',
    last_name: '',
    bio: '',
    profile_picture: '',
};

export default initialValues;
